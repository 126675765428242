import { autoSEOComponentPage as core } from '@krasia/core/utils/seo'

export const defaultTitle = '36氪出海'
export const defaultDescription =
  '36氪出海是关注全球新经济的中文媒体品牌。我们通过前沿商业内容、出海交流社群和跨境服务，助力中国企业全球化'

export function autoSEOComponentPage(
  Component: React.ComponentType<any>,
  options: {
    additionalHead?: React.ReactNode
    defaultTitle?: string
    defaultDescription?: string
  } = { defaultTitle, defaultDescription }
) {
  return function WithSEO(props: { seo?: string; url?: string; title?: string; description?: string }) {
    return core(Component, options)(props)
  }
}

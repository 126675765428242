import React from 'react'
import { sendPageView } from '@krasia/core/fetchers'
import { Box } from '../../components/Box'
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { Layout, Collection, Section, Post, Promotion, MenuValue, PulseList } from '../../types'
import { renderRows } from '../../utils/sections'
import useAd from '../../utils/useAd'
import { headerLinks, headerTexts, footerLinks, footerTexts } from '../../config'
import { useMediaQuery } from '@chakra-ui/react'

export type HomeProps = {
  headerMenu: { [key: string]: MenuValue }
  promotions: {
    [key: string]: Promotion[]
  }
  result: { section: Layout; data?: Collection | Section | Post[] | PulseList }[]
  error: boolean
}

function Home({ result, promotions, headerMenu }: HomeProps) {
  const rows = renderRows(result, promotions)

  const [isMobile] = useMediaQuery(['(max-width: 768px)'])

  const topAd = useAd({
    key: 'homepage-top',
    promotions,
    onSendView: sendPageView
  })

  const adLastSection = useAd({
    key: 'above-last-section',
    promotions,
    onSendView: sendPageView
  })

  const adFeatureTop = useAd({
    key: 'featured-top',
    promotions,
    onSendView: sendPageView
  })

  const belowFirstRow = useAd({
    key: 'below-first-row',
    promotions,
    onSendView: sendPageView
  })

  const adFeatureBottom = useAd({
    key: 'featured-bottom',
    promotions,
    onSendView: sendPageView
  })

  if (adLastSection.ad && rows.length > 1) {
    rows.splice(rows.length - 1, 0, adLastSection.autoRender)
  }

  if (adFeatureTop.ad && rows.length > 1) {
    rows.splice(1, 0, adFeatureTop.autoRender)
  }

  if (!adFeatureTop.ad && belowFirstRow.ad && rows.length > 1) {
    rows.splice(2, 0, belowFirstRow.autoRender)
  } else if (adFeatureTop.ad && belowFirstRow.ad && rows.length > 1) {
    rows.splice(3, 0, belowFirstRow.autoRender)
  }

  return (
    <>
      {!isMobile && promotions && promotions['homepage-top'] && promotions['homepage-top'].length > 0 && (
        <Box backgroundColor={'#3a3a3a'} display={{ base: 'none', md: 'block' }}>
          {topAd.autoRender}
        </Box>
      )}

      <Header headerMenu={headerMenu} links={headerLinks} texts={headerTexts} />

      {isMobile && promotions && promotions['homepage-top'] && promotions['homepage-top'].length > 0 && (
        <Box display={{ base: 'block', md: 'none' }} marginTop={'40px'}>
          {topAd.autoRender}
        </Box>
      )}

      {rows.map((r, key) => {
        return <Box key={key}>{r}</Box>
      })}
      {adFeatureBottom.autoRender}
      <Footer listLink={footerLinks} texts={footerTexts} />
    </>
  )
}

export default Home

import { fetchHomeData } from '../fetchers/home'

import Home from '../containers/Home'

import { autoSEOComponentPage } from '../utils/seo'
import { GetServerSideProps } from 'next'

const HomePage = autoSEOComponentPage(Home)

export default HomePage

export const getStaticProps: GetServerSideProps = async ({ query }) => {
  const { promotions, headerMenu, result, error, seo } = await fetchHomeData()
  if (query && Object.keys(query).length > 0 && !query.utm_source) {
    return {
      redirect: {
        destination: `/`,
        statusCode: 301
      }
    }
  }
  return {
    props: {
      url: 'https://letschuhai.com/',
      seo,
      promotions,
      headerMenu,
      result,
      ...(error && {
        error
      })
    },
    revalidate: 60 // 1 min
  }
}
